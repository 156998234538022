<template>
  <div class="d-flex" id="main">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-10 col-sm-12" v-show="(formProcess.step == 1)">
         <b-card>
            <b-card-text>
              <div class="row">
                <div class="col-md-4">
                  <h4 class="m-0 p-0"><b>Client Details:</b></h4><hr>
                  <div class="row pb-3">
                    <div class="col-md-12"><b>Name:</b></div>
                    <div class="col-md-12 text-right">
                      <b-form-input size="sm" v-model="form.name" placeholder="Mr John Doe" class="mt-0"></b-form-input>
                    </div>
                  </div>
                  <div class="row pb-3">
                    <div class="col-md-12"><b>Postcode:</b></div>
                    <div class="col-md-12 text-right">
                      <b-form-input size="sm" v-model="form.postcode" placeholder="LU1 2NA" class="mt-0 text-uppercase"></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <h4 class="m-0 p-0"><b>Household Rooms:</b></h4><hr>
                  <div class="row rooms-rows">
                    <div class="col-12">
                      <label for="household-livingroom">Living/Reception:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-livingroom" v-model="form.household.livingroom" min="0" max="3" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                    <div class="col-12">
                      <label for="household-Kitchen">Kitchen:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-Kitchen" v-model="form.household.kitchen" min="0" max="1" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                    <div class="col-12">
                      <label for="household-bedrooms">Bedrooms:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-bedrooms" v-model="form.household.bedrooms" min="0" max="5" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                    <div class="col-12">
                      <label for="household-bathtoilet">Toilet/Bathroom/Ensuite :</label>  
                      <b-form-spinbutton inline class="float-right" id="household-bathtoilet" v-model="form.household.bathtoilet" min="0" max="7" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                    <div class="col-12">
                      <label for="household-hallwayStairs">Halways: <small class="text-muted">Inc. Stairs</small>:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-hallwayStairs" v-model="form.household.hallwayStairs" min="0" max="3" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                    <div class="col-12">
                      <label for="household-other">Other Rooms:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-other" v-model="form.household.other" min="0" max="5" placeholder="--" size="sm"></b-form-spinbutton>
                   </div>
                    <div class="col-12">
                      <label for="household-exterior">Exterior: <small class="text-muted">Walls/Guttering</small>:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-exterior" v-model="form.household.exterior" min="0" max="1" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                    <div class="col-12">
                      <label for="household-defects">Other Defects: <small class="text-muted">Boiler/Heating/Gas</small>:</label>  
                      <b-form-spinbutton inline class="float-right" id="household-defects" v-model="form.household.defects" min="0" max="5" placeholder="--" size="sm"></b-form-spinbutton>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-text>
            <b-button variant="dark" class="m-md-2" to="/">Cancel</b-button>
            <b-button variant="success" class="float-right m-md-2" @click="nextbutton">Next ></b-button>
            <b-dropdown id="dropdown-1" variant="info" class="float-right m-md-2" text="Previous Sessions">
              <b-dropdown-item v-for="(item, index) in previousSessions" :key="index" @click="loadSession(index)">{{item.name}}({{item.postcode}})</b-dropdown-item>
            </b-dropdown>
          </b-card>
        </div>
        <div class="col-11" v-show="(formProcess.step == 2)">
          <b-card no-body footer-tag="footer">
            <b-tabs pills card vertical>

              <div v-for="(item, index) in form.household" :key="index">
                <div v-if="(item >= 1)">
                  <div v-for="(rooms, number) in item" :key="number">
                    <b-tab :title="(RoomLabels(index))+' '+(number+1)" @click="tabActvate( (index)+(number+1) )">
                      <b-card-text>
                        <vue-dropzone :ref="(index)+(number+1)" :id="(index)+(number+1)" @vdropzone-file-added="onDropzoneSuccess" :options="dropzoneOptions"></vue-dropzone>
                      </b-card-text>
                    </b-tab>
                  </div>
                </div>
              </div>
              <b-tab title="Summary" top active>
                <div class="row">
                  <div class="col-12"><b>Summary</b><br><code> Please use the left navigation to upload your files into the corrent rooms. </code><hr></div>
                  <div class="col-12">
                    <h6>Customer Details<hr class="mt-0"></h6>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-6"><b>Name:</b></div>
                        <div class="col-4 text-right">{{form.name}}</div>
                      </div>
                      <div class="row">
                        <div class="col-6"><b>Postcode:</b></div>
                        <div class="col-4 text-right">{{form.postcode}}</div>
                      </div>
                    </div>
                    <h6><br>Household Rooms<hr class="mt-0"></h6>
                    <div class="col-12">
                        <div class="row" v-for="(item, index) in form.household" :key="index">
                          <div class="col-6"><b>{{RoomLabels(index)}}:</b></div>
                          <div class="col-4 text-right">{{item}}</div>
                        </div>
                    </div>
                  </div>
                </div>
              </b-tab>


            </b-tabs>
            <template #footer>
              <b-button variant="dark" @click="backbutton">Go Back</b-button>
              <b-button variant="success" class="float-right" @click="finshedbutton">Finshed</b-button>
            </template>            
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { mapActions } from "vuex";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    name: 'application-form',
    components: {
      vueDropzone: vue2Dropzone
    },
    data() {
      return {
        dropzoneOptions: {
          url:"http://..",
          dictDefaultMessage: "Upload Your Images Here by drag and drop or click here!",
          duplicateCheck:true,
          //autoProcessQueue:false,
          addRemoveLinks: true
        },
        formProcess:{
          step: 1,
          activeNextButton:false
        },
        form: {
          name: "",
          postcode: "",
          household:{
            livingroom: 0,
            kitchen: 0,
            bedrooms: 0,
            bathtoilet: 0,
            hallwayStairs:0,
            exterior:0,
            defects:0,
            other:0
          },
          images: []
        },
        householdLabels:{
          bedrooms: "Bedroom",
          livingroom: "Living / Reception Room",
          kitchen: "Kitchen",
          bathtoilet: "Bath Room / Toilet",
          hallwayStairs: "Hallway / Stairs",
          other: "Other",
          exterior: "Exterior",
          defects: "Defects"
        },
        previousSessions:[]
      }
    },
    methods: {
      ...mapActions(["SetupCustomer","validateCustomer","finshedCustomer","getAuthToken"]),
      validate_Step1(){
        var count = 0;
        var rooms = this.form.household;
        for (var i=0; i<rooms.length; i++){ count += 1; }
        this.form.total_rooms = count;
        return true;
      },
      RoomLabels(id){ return this.householdLabels[id]; },
      async loadSession(i){
        var validate = await this.validateCustomer(this.previousSessions[i]);
        this.form = validate.form;
        this.formProcess.step = 2;
      },
      async nextbutton(){ 
        if(this.validate_Step1()){
          try {
            var r = await this.SetupCustomer(this.form);
            this.form = r.info;
            this.formProcess.step = 2;
          } catch (error) {
            this.$bvToast.toast(error.response.data.message,{ title: `Server Error:`, variant: 'danger', solid: true});
          }
        } 
      },
      backbutton(){ this.formProcess.step = 1; },
      async finshedbutton(){
        var forms = this.form;
        var logFinshed = await this.finshedCustomer(forms);
        console.log("logFinshed",logFinshed);
        this.formProcess.step = 3;
      },
      async tabActvate(roomName){
        var token = await this.getAuthToken();
        console.log(token);
        this.$refs[roomName][0].setOption('url', axios.defaults.baseURL+"upload/"+roomName);
        this.$refs[roomName][0].setOption('headers', { "Authorization": "Bearer "+token });
        this.$refs[roomName][0].enable();
      },
      onDropzoneSuccess(file, response) {
        console.log("response:",response);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base64Image = reader.result;
          console.log(base64Image);
          this.form.images.push(base64Image);
        };
      },
    },
    created() {
      // Previous Sessions
        const data = Object.keys(sessionStorage);
        for (var i=0; i < data.length; i++) { this.previousSessions[i] = JSON.parse(sessionStorage.getItem(data[i])); }
      //---
    }
  }
</script>
<style scoped>
  .rooms-rows{  }
  .rooms-rows .col-12{
    border-bottom: 1px solid #00000014;
    border-left: 1px solid #00000014;
    margin-bottom: 7px;
    padding-bottom: 7px;
  }
  .rooms-rows .col-12 label{
    font-weight:700;
  }
  #main {
      height: 100vh;
  }  
  nav {
    display: none !important;
  }
</style>