import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
//import AboutView from '../views/AboutView.vue'
import error404 from '../views/error404.vue'
import Application from '../views/Application.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/start',
    name: 'Application',
    component: Application
  },

  {
    path: '/404',
    name: '404',
    component: error404
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
