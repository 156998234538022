<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <center>
          <img alt="Vue logo" src="../assets/HousingRepairLogo.png">
          <h2 class="p-0 m-0">{{ msg }}</h2>
          <a href="/start" class="btn btn-dark"> Create New Share</a>

        </center>
      </div>
    </div>





  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      list_clients: []
    }
  },
  created() {
    const data = Object.keys(sessionStorage);
    for (var i=0; i < data.length; i++) {
      this.list_clients[i] = JSON.parse( sessionStorage.getItem(data[i]) );
    }
  }
}

</script>
<style scoped>
img{ filter: drop-shadow(7px 5px 1px #ccc); }
ul { list-style-type: none; }
</style>
