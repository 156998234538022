import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('vue2-animate/dist/vue2-animate.min.css')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'));

import axios from 'axios';
Vue.use(axios);
axios.defaults.baseURL = 'http://localhost:8080/static/';
axios.defaults.withCredentials = true
if (store.getters.StateToken) {
  axios.defaults.headers.common['Authorization'] ='Bearer '+store.getters.StateToken;
}



/*
//axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers.common['X-Requested-With'] ='POST';
  axios.interceptors.response.use(undefined, function(error) {'/auth/validate.json', console.log("Warren interceptor:", store.checkToken() );
    if (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        store.dispatch("LogOut");
        return router.push("/");
      }
    }
  });
*/

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
