import axios from "axios";

const state = {
  token: null
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StateToken: (state) => state.token,
  Statehousehold: (state) => state.household,
};

const actions = {
  async SetupCustomer({commit}, user) {
    let res = await axios.post("customer/", user);
    if (res.data.success !== null){
      await commit("setToken", res.data.response.token);
      user.token = res.data.response.token;
//      user.tokentimeout = res.data.data
      sessionStorage.setItem("list_"+(user.name).trim(), JSON.stringify(res.data.response.info));
      return res.data.response;
    }
  },
  async validateCustomer({commit},user) {
    axios.defaults.headers.post['Authorization'] = 'Bearer '+user.token;
    let res = await axios.post("validate/");
    if (res.data.error){
      await commit("setToken", null);
      sessionStorage.removeItem("list_"+(user.name).trim());
      return res.data;
    }
    if (res.data.success !== null){
      await commit("setToken", user.token);
      return res.data.data;
    }
  },
  async finshedCustomer({commit, state},user) {
    console.log(user);
    axios.defaults.headers.common['Authorization'] = 'Bearer '+ state.token;
    let res = await axios.post("finshed/");
    console.log("Token: ",state," res: ",res," user: ",user);

    commit("clearToken", "");
//    await commit("setToken", null);
//    sessionStorage.removeItem("list_"+(user.name).trim());
    return res;
  },
  async getAuthToken({state}) {
    return state.token;
  },
  async LogOut({ commit }){
    let user = null;
    commit("logout", user);
  }
};

const mutations = {
  clearToken(state,token) { state.token = token; },
  setToken(state, token) {
    state.token = token;
  },
  logout(state, user) {
    state.user = user;
  },
};

export default { state, getters, actions, mutations };